<script>
    import { onMount } from 'svelte';
    import {
        configureClient,
        getAccessToken,
        getAccessTokenClaim,
        isAuthenticated,
        isNewTokenStructure,
        login,
        signOutUser,
    } from '@ctm/auth';
    import { auth_domain, auth_client } from './environment.js';
    import Logo from './ctm-logo.svg';

    const BASE_URL = `${window.location.protocol}//${window.location.host}`;
    const uiVersion = __VERSION__ || '-';
    const apiVersion = window?.version || '-';
    const params = new URLSearchParams(window.location.search);

    const auth0ClientOptions = {
        domain: auth_domain,
        client_id: auth_client,
        redirect_uri: location.protocol + '//' + location.host,
        audience: 'http://config',
        scope: 'openid email profile',
        leeway: 60,
    };

    const authConfig = {
        callbackUrl: BASE_URL,
        auth0ClientOptions,
    };

    let error = params.get('error');
    let error_description = params.get('error_description');
    let initialising = true;
    let signingIn = false;
    let _isAuthenticated = false;
    let _isNewTokenStructure;
    let _accessToken = null;
    let willRedirect = false;

    async function sign_out(redirect = false) {
        sessionStorage.removeItem('signOut');
        signOutUser(redirect ? 'showAuthLogin' : null);
    }

    onMount(async () => {
        signingIn = false;

        const params = new URLSearchParams(window.location.search);
        if (params.get('showAuthLogin') === 'true') {
            willRedirect = true;
        }

        localStorage.removeItem('oauth');
        let signOut = sessionStorage.getItem('signOut');

        try {
            await configureClient(authConfig);
        } catch (e) {
            console.error(e);
            error = e;
            error_description = error.message;
            return;
        }

        if (signOut) {
            sign_out();
            return;
        }

        _isAuthenticated = await isAuthenticated();
        _isNewTokenStructure = await isNewTokenStructure();

        const sso = params.get('sso');
        if (!_isAuthenticated && !sso) return (initialising = false);

        await _getAccessToken();

        if (_accessToken) {
            const fullname = await getAccessTokenClaim('http://ctm.com/fullName');
            const username = await getAccessTokenClaim('http://ctm.com/login');

            const auth0Token = {
                from: 'auth0',
                access_token: _accessToken,
                fullname,
                token_type: 'bearer',
                username,
            };
            localStorage.setItem('oauth', JSON.stringify(auth0Token));

            removeSearchParam();
            window.location.href = '/#/debtors';
            if (window.svelteComponents && window.svelteComponents.signInComponent) {
                window.svelteComponents.signInComponent.$destroy();
                window.svelteComponents.signInComponent = null;
            }
        }
    });

    async function _getAccessToken() {
        _accessToken = await getAccessToken();
    }

    async function signIn() {
        try {
            signingIn = true;
            if (error) {
                sign_out(true);
                return;
            }

            await login();
        } catch (error) {
            console.error(error);
            signingIn = false;
        }
    }

    async function removeSearchParam() {
        const url = new URL(window.location.href);
        url.search = '';
        window.location.href = url.toString();
    }
</script>

<div class="app">
    {#if error}
        <div class="error">
            {error_description}
        </div>
    {/if}

    {#if initialising || willRedirect}
        <div class="initialising">
            <Logo width="100px" />
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                <path d="M4 12a8 8 0 0 1 8-8" stroke-linecap="round" />
            </svg>
        </div>
    {:else}
        <header>
            <Logo width="300px" height="100px" />
        </header>

        <button on:click={signIn} type="button" disabled={signingIn}
            >{error ? 'Sign out' : signingIn ? 'Signing in...' : 'Sign-in'}</button>

        <a href="/#/login">Legacy login</a>
    {/if}


    <footer>
        <span>UI version: {uiVersion}</span>
        <span>API version: {apiVersion}</span>
    </footer>
</div>

<style>
    .app {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: calc(100dvh - 75px - 15rem); /* 75px, 15rem from NG CSS */
        gap: 16px;
        width: 100dvw;
    }

    header {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .initialising {
        display: grid;
        place-items: center;
    }

    button {
        height: 48px;
        padding: 0 24px;
        border-radius: 4px;
        outline: none;
        border: none;
        border-radius: 3px;
        font-weight: normal;
        text-align: center;
        margin: 0;
        cursor: pointer;
        font-size: 18px;
        line-height: 22px;
        background-color: #007aff;
        color: #ffffff;
        transition: background-color 0.3s ease;
    }

    button:hover {
        background-color: #006fe8;
    }

    button:focus {
        outline: 2px solid #006fe8;
        outline-offset: 1px;
    }

    button:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }

    a {
        border-bottom: 1px solid #007aff;
        color: #007aff;
    }

    a:hover {
        text-decoration: none;
    }

    .error {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: red;
        color: white;
        padding: 16px;
    }

    .initialising svg {
        animation: spin .4s linear infinite;
        color: #007aff;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    footer {
        position: fixed;
        bottom: 0;
        display: flex;
        gap: 16px;
        font-size: 12px;
        justify-content: center;
        padding: 16px 0;
    }
</style>
