import HotelRankings from './hotel-rankings/HotelRankings.svelte';
import SignIn from './sign-in/SignIn.svelte';
function bootstrapSwitch() {
    const sheets = [...document.getElementsByTagName('link')];

    const bootstrapThree = sheets.find((sheet) => {
        const href = sheet.getAttribute('href');
        return href.includes('3.1.1');
    });

    if (bootstrapThree) bootstrapThree.setAttribute('disabled', true);

    const bootstrapFive = sheets.find((sheet) => {
        const href = sheet.getAttribute('href');
        return href.includes('5.1.1');
    });

    if (bootstrapFive) bootstrapFive.removeAttribute('disabled');
}

const hotelRankings = () => {
    bootstrapSwitch();

    new HotelRankings({
        target: document.body,
    });
};

window.svelteComponents = window.svelteComponents || {};

const signIn = () => {

    window.svelteComponents.signInComponent = new SignIn({
        target: document.body,
    });
};

window.svelte = { hotelRankings, signIn };
