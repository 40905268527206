const clients = {
	'localhost:3002': 'qboKZIqETnJYhzg3Vf6Et0pGGQFpl8SA',
	'lightning-config-api-dev': 'nO8p9nMxTCGk9Y6FyWH7WhVAMbBKcAW6',
	'lightning-config-api-qa': 'qboKZIqETnJYhzg3Vf6Et0pGGQFpl8SA',
	'lightning-config-qasab': 'qboKZIqETnJYhzg3Vf6Et0pGGQFpl8SA',
	'config-staging': 'TVe5yzQdY3NGANM7vdnsh8riYrTAIWrl',
	'lightning-config-api-a-prod-preprod': 'J2UXeTuzdTZ4dKJkJ2Dv6FOo3KGajY78',
	config: 'J2UXeTuzdTZ4dKJkJ2Dv6FOo3KGajY78'
};

const domains = {
	'localhost:3002': 'travelctm-au-qa.au.auth0.com',
	'lightning-config-api-dev': 'travelctm-au-development.au.auth0.com',
	'lightning-config-api-qa': 'travelctm-au-qa.au.auth0.com',
	'lightning-config-qasab': 'travelctm-au-qa.au.auth0.com',
	'config-staging': 'travelctm-au-staging.au.auth0.com',
	'lightning-config-api-a-prod-preprod': 'travelctm-au-production.au.auth0.com',
	config: 'travelctm-au-production.au.auth0.com'
};

const host = location.host.split('.')[0];

export const auth_client = clients[host];
export const auth_domain = domains[host];
