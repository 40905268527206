<svg width="120px" height="60px" viewBox="0 0 315 107" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" {...$$props} >
   <title>CTM Logo</title>
    <desc>CTM Logo - Color - Landscape.</desc>
    <defs>
        <polygon id="path-1" points="0.7624 0.8106 37.2917656 0.8106 37.2917656 36 0.7624 36"></polygon>
        <polygon id="path-3" points="0.763319964 0.6675 37.291 0.6675 37.291 36.7910263 0.763319964 36.7910263"></polygon>
    </defs>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="CTM/Full/Landscape/Colour">
            <g>
                <path d="M38.2682,69.4548 C35.0752,72.4758 32.0212,74.4868 27.5012,74.4868 C20.6252,74.4868 15.8232,69.2268 15.8232,61.6948 L15.8232,61.5088 C15.8232,54.4408 20.7082,48.9038 26.9442,48.9038 C31.7112,48.9038 34.6442,50.9748 37.5812,54.0838 L38.3052,54.8508 L47.8352,43.7168 L47.2302,43.0968 C41.5202,37.2348 35.6702,34.8438 27.0372,34.8438 C11.6232,34.8438 0.0002,46.3868 0.0002,61.6948 L0.0002,61.8808 C0.0002,76.8328 11.7942,88.5458 26.8512,88.5458 C37.7932,88.5458 43.4262,84.0248 47.8842,79.1388 L48.4912,78.4728 L38.9422,68.8168 L38.2682,69.4548 Z" id="Fill-1" fill="#002A3A"></path>
                <path d="M74.5021,17.6438 L58.4931,24.1878 L58.4931,35.7698 L52.4601,35.7508 L52.4601,49.7388 L58.4931,49.7388 L58.4931,72.3728 C58.4931,83.2168 63.3971,88.2688 73.9251,88.2688 C78.3961,88.2688 82.0901,87.3118 85.5501,85.2568 L86.0141,84.9818 L86.0141,71.5178 L84.6111,72.2818 C82.6061,73.3748 80.4161,73.9298 78.1021,73.9298 C75.5121,73.9298 74.5021,72.8418 74.5021,70.0508 L74.5021,49.7388 L86.2001,49.7388 L86.2001,35.7718 L74.5021,35.7718 L74.5021,17.6438 Z" id="Fill-3" fill="#002A3A"></path>
                <path d="M153.2047,34.8444 C146.8717,34.8444 141.5687,37.2104 137.0337,42.0704 C134.0977,37.3974 129.1117,34.8444 122.8457,34.8444 C116.2937,34.8444 112.0927,37.9054 109.1247,41.1644 L109.1247,35.7734 L93.1157,35.7514 L93.1157,87.4314 L109.1247,87.4314 L109.1247,60.1514 C109.1247,53.8124 112.1767,49.5534 116.7177,49.5534 C121.5707,49.5534 124.0317,52.6394 124.0317,58.7244 L124.0317,87.4314 L140.0417,87.4314 L140.0417,60.1514 C140.0417,53.8124 143.0927,49.5534 147.6337,49.5534 C152.4867,49.5534 154.9477,52.6394 154.9477,58.7244 L154.9477,87.4314 L170.9577,87.4314 L170.9577,54.0814 C170.9577,41.8564 164.4867,34.8444 153.2047,34.8444" id="Fill-5" fill="#002A3A"></path>
                <g id="Group-9" transform="translate(234.000000, 70.324000)">
                    <mask id="mask-2" fill="white">
                        <use xlink:href="#path-1"></use>
                    </mask>
                    <g id="Clip-8"></g>
                    <path d="M5.4674,3.9306 C3.8634,4.6986 2.2884,5.5676 0.7624,6.5596 C1.8774,12.6326 4.3664,18.5486 8.3064,23.8346 C12.2454,29.1196 17.2024,33.1956 22.7024,36.0006 C32.8304,27.3876 37.8734,14.7926 37.2384,2.2666 C35.5344,1.6636 33.7944,1.1866 32.0334,0.8106 C23.6094,4.8436 14.2554,5.8086 5.4674,3.9306" id="Fill-7" fill="#008ACA" mask="url(#mask-2)"></path>
                </g>
                <path d="M259.6569,54.0173 C256.8259,50.2173 254.7549,46.0883 253.3929,41.8133 C251.9129,40.7973 250.3819,39.8723 248.7999,39.0663 C247.4219,40.2383 246.1449,41.4903 244.9569,42.7993 C244.2699,51.8173 240.6579,60.5683 234.3399,67.5283 C234.2069,69.2793 234.1719,71.0383 234.2609,72.7973 C235.9639,73.4013 237.7059,73.8783 239.4679,74.2543 C247.8919,70.2223 257.2459,69.2573 266.0339,71.1353 C267.6379,70.3673 269.2129,69.4983 270.7389,68.5063 C270.4259,66.7993 269.9909,65.1073 269.4589,63.4353 C265.7919,60.9203 262.4629,57.7813 259.6569,54.0173" id="Fill-10" fill="#00A2AC"></path>
                <path d="M245.0358,37.5251 C243.3268,36.9201 241.5788,36.4421 239.8108,36.0651 C231.4298,40.0391 222.1388,40.9871 213.4048,39.1251 C211.7518,39.9091 210.1308,40.7961 208.5608,41.8171 C208.8768,43.5351 209.3138,45.2371 209.8508,46.9191 C213.5158,49.4341 216.8438,52.5711 219.6478,56.3341 C222.4738,60.1251 224.5398,64.2421 225.9008,68.5071 C227.3848,69.5251 228.9168,70.4501 230.5008,71.2581 C231.8778,70.0871 233.1518,68.8371 234.3398,67.5281 C235.0258,58.5111 238.6388,49.7591 244.9568,42.7991 C245.0908,41.0471 245.1258,39.2861 245.0358,37.5251" id="Fill-12" fill="#00A2AC"></path>
                <path d="M216.1051,59.092 C213.2801,55.301 211.2121,51.184 209.8511,46.919 C208.3681,45.902 206.8341,44.975 205.2511,44.168 C195.1261,52.78 190.0841,65.373 190.7191,77.897 C202.5391,82.082 216.0451,80.849 227.1911,73.607 C226.8761,71.89 226.4381,70.188 225.9011,68.507 C222.2361,65.992 218.9091,62.855 216.1051,59.092" id="Fill-14" fill="#008ACA"></path>
                <g id="Group-18" transform="translate(277.000000, 33.324000)">
                    <mask id="mask-4" fill="white">
                        <use xlink:href="#path-3"></use>
                    </mask>
                    <g id="Clip-17"></g>
                    <path d="M29.7477,12.8325 C25.8087,7.5475 20.8517,3.4715 15.3517,0.6675 C13.9737,1.8395 12.6977,3.0915 11.5097,4.4005 C10.8227,13.4185 7.2107,22.1695 0.8927,29.1295 C0.7587,30.8805 0.7237,32.6395 0.8117,34.3985 C12.6337,38.5855 26.1437,37.3515 37.2917,30.1075 C36.1767,24.0345 33.6857,18.1185 29.7477,12.8325" id="Fill-16" fill="#79B829" mask="url(#mask-4)"></path>
                </g>
                <path d="M288.5885,32.4499 C276.7685,28.2639 263.2595,29.4989 252.1125,36.7419 C252.4265,38.4499 252.8605,40.1409 253.3935,41.8129 C257.0605,44.3279 260.3895,47.4669 263.1945,51.2309 C266.0265,55.0319 268.0975,59.1599 269.4585,63.4349 C270.9405,64.4509 272.4715,65.3759 274.0535,66.1829 C275.4305,65.0119 276.7045,63.7619 277.8925,62.4539 C278.5785,53.4359 282.1915,44.6849 288.5095,37.7239 C288.6435,35.9719 288.6785,34.2109 288.5885,32.4499" id="Fill-19" fill="#4BB065"></path>
                <path d="M239.8112,36.0647 C241.4632,35.2807 243.0842,34.3937 244.6552,33.3727 C243.5402,27.2997 241.0502,21.3837 237.1112,16.0977 C233.1722,10.8137 228.2152,6.7367 222.7162,3.9327 C221.3372,5.1047 220.0622,6.3567 218.8742,7.6657 C218.1862,16.6837 214.5752,25.4347 208.2562,32.3957 C208.1232,34.1457 208.0872,35.9047 208.1762,37.6637 C209.8872,38.2697 211.6352,38.7487 213.4052,39.1257 C221.7872,35.1507 231.0772,34.2027 239.8112,36.0647" id="Fill-21" fill="#4BB065"></path>
                <path d="M218.9528,2.3918 C207.1328,-1.7942 193.6238,-0.5602 182.4768,6.6838 C183.5928,12.7568 186.0818,18.6728 190.0208,23.9588 C193.9598,29.2438 198.9178,33.3208 204.4178,36.1248 C205.7948,34.9538 207.0688,33.7038 208.2568,32.3948 C208.9428,23.3778 212.5548,14.6258 218.8738,7.6658 C219.0068,5.9128 219.0418,4.1528 218.9528,2.3918" id="Fill-23" fill="#79B829"></path>
                <path d="M208.2565,32.3957 C214.5745,25.4357 218.1865,16.6847 218.8735,7.6667 C212.5545,14.6267 208.9435,23.3777 208.2565,32.3957 M239.8115,36.0647 C231.0765,34.2037 221.7865,35.1517 213.4045,39.1257 C222.1385,40.9877 231.4295,40.0397 239.8115,36.0647 M277.8925,62.4547 C284.2105,55.4937 287.8225,46.7427 288.5095,37.7247 C282.1915,44.6857 278.5785,53.4367 277.8925,62.4547 M253.3935,41.8137 C254.7545,46.0897 256.8255,50.2177 259.6565,54.0177 C262.4625,57.7817 265.7915,60.9207 269.4585,63.4367 C268.0975,59.1607 266.0275,55.0317 263.1945,51.2317 C260.3895,47.4677 257.0605,44.3287 253.3935,41.8137 M209.8515,46.9197 C211.2125,51.1847 213.2795,55.3017 216.1055,59.0927 C218.9085,62.8547 222.2355,65.9927 225.9015,68.5077 C224.5395,64.2427 222.4735,60.1247 219.6485,56.3347 C216.8435,52.5717 213.5165,49.4347 209.8515,46.9197 M234.3395,67.5287 C240.6575,60.5687 244.2705,51.8177 244.9565,42.7997 C238.6385,49.7607 235.0265,58.5117 234.3395,67.5287 M266.0335,71.1357 C257.6095,75.1677 248.2555,76.1327 239.4675,74.2547 C247.8925,70.2227 257.2455,69.2577 266.0335,71.1357" id="Fill-25" fill="#006053"></path>
            </g>
        </g>
    </g>
</svg>